import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './RadioChoice.module.scss';
import { IRadioChoice } from './IRadioChoice';
import { IName } from '../../types/types';

const RadioChoice: React.FC<IRadioChoice & IName> = ({ route, choices }) => {
  const history = useHistory();
  const handleClick = () => {
    setTimeout(() => history.push(route), 500);
  };

  return (
    <div className={styles.container}>
      {choices.map((choice) => (
        <p key={choice} onClick={handleClick} className={styles.choice}>
          {choice}
        </p>
      ))}
    </div>
  );
};

export default RadioChoice;
