import React from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'src/components/Loader/Loader';
import LoaderList from 'src/components/LoaderList/LoaderList';
import { ROUTES } from 'src/constants/routes';

import styles from './Processing.module.scss';

const Processing = () => {
  const history = useHistory();

  const list = [
    'Analysing your answers...',
    'Designing unique natal charts...',
    'Calculating your profile...',
    'Calculating your compatibility with other signs...',
    'Generating a report...',
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Please wait until we analyze your answers and create you compatibility report...
      </h1>
      <Loader onComplete={() => history.push(ROUTES.POST_QUIZ)} />
      <LoaderList list={list} />
    </div>
  );
};

export default Processing;
