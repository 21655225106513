import HttpClient from 'src/api/HttpClient';
import { LocationService } from 'src/location/services';
import { CookiesService } from './CookiesService';
import { PurchaseService } from './PurchaseService';
import { AnalyticsService } from './AnalyticsService';
import { AuthService } from './AuthService';

export * from './CookiesService';
export * from './AnalyticsService';
export * from './PurchaseService';
export * from './AuthService';

export interface Services {
  cookiesService: CookiesService,
  analyticsService: AnalyticsService,
  purchaseService: PurchaseService,
  authService: AuthService;
  locationService: LocationService;
}

export const initServices = async (): Promise<Services> => {
  const httpClient = new HttpClient();
  const cookiesService = new CookiesService();
  const analyticsService = new AnalyticsService();
  const purchaseService = new PurchaseService(httpClient);
  const authService = new AuthService(httpClient);
  const locationService = new LocationService(httpClient);

  return {
    cookiesService,
    analyticsService,
    purchaseService,
    authService,
    locationService,
  };
};
