import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/trust.svg';
import QuizContent from 'src/components/QuizContent/QuizContent';
import RadioChoice from 'src/components/RadioChoice/RadioChoice';
import { ROUTES } from '../../constants/routes';

const choices = ['Yes, absolutely', 'Not sure', 'No, I am very open'];

const Trust = () => (
  <QuizContent
    title="Would you say that you only share your inner workings to people you trust greatly?"
    img={<Img title="trust" />}
    step={4}
    radio
    routeBack={ROUTES.DETAILS}
  >
    <RadioChoice route={ROUTES.MAIL} choices={choices} name="trust" />
  </QuizContent>
);
export default Trust;
