/* eslint-disable quotes,react/no-unescaped-entities */
import React from 'react';
import styles from './ContactUs.module.css';

const ContactUs = () => (
  <section className={styles.contact}>
    <h1 className={styles.title}>Contact Us</h1>
    <p className={styles.text}>
      We will be glad to assist you via email. Please send your questions and
      feedback to
      <a href="mailto:support@my-stars.co">
        {" "}
        support@my-stars.co
      </a>
    </p>
  </section>
);

export default ContactUs;
