import React from 'react';
import guarantee from 'src/assets/images/postQuiz/satisfaction/guarantee.png';

import styles from './Satisfaction.module.scss';

const Satisfaction = () => (
  <div className={styles.container}>
    <div>
      <h2 className={styles.title}>Our #1 priority here at LUNAR is your satisfaction</h2>
      <img className={styles.img} src={guarantee} alt="guarantee" />
      <p className={styles.text}>
        Which means we guarantee that you will receive top-notch service. If you have a problem,
        reach out to us and we&apos;ll make a refund. No questions asked.
      </p>
    </div>
  </div>
);

export default Satisfaction;
