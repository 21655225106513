import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/details.svg';
import QuizContent from 'src/components/QuizContent/QuizContent';
import RadioChoice from 'src/components/RadioChoice/RadioChoice';
import { ROUTES } from '../../constants/routes';

const choices = ['Totally yes', 'Maybe, sometimes', 'Not really'];

const Details = () => (
  <QuizContent
    title="Do you remember tiny details about the people you care about?"
    img={<Img title="details" />}
    radio
    step={3}
    routeBack={ROUTES.OVERTHINK}
  >
    <RadioChoice route={ROUTES.TRUST} choices={choices} name="details" />
  </QuizContent>
);
export default Details;
