import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/gender.svg';
import GenderComponent from 'src/components/Gender/Gender';
import QuizContent from 'src/components/QuizContent/QuizContent';
import { ROUTES } from 'src/constants/routes';
import useGender from './useGender';

const Gender = () => {
  useGender();
  return (
    <QuizContent
      title="To which gender identity do you most identify?"
      img={<Img title="gender" />}
      step={1}
      radio
      routeBack={ROUTES.FLOW}
    >
      <GenderComponent
        route={ROUTES.OVERTHINK}
        text="Gender is important for personalizing your readings."
        name="gender"
      />
    </QuizContent>
  );
};

export default Gender;
