import React from 'react';
import Button from 'src/components/common/Button/Button';
import StoreLink from 'src/components/StoreLink/StoreLink';
import AppStore from 'src/assets/images/appstore.png';
import GooglePlay from 'src/assets/images/googleplay.png';
import Copyright from 'src/components/Copyright/Copyright';

import styles from './Success.module.scss';

const Success = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>Success!</h3>
    <p className={styles.text}>Please download your report and install the app</p>
    <a
      href="assets/Taurus%20compatibility.pdf"
      download
    >
      <Button
        text="Download PDF report"
        className={styles.button}
        wrapperClassName={styles.button_wrapper}
      />
    </a>
    <div className={styles.store_link_container}>
      <StoreLink
        src={AppStore}
        wrapperClassName={styles.store_link}
        href="https://apps.apple.com/us/app/nebula-horoscope-astrology/id1459969523"
      />
      <StoreLink
        src={GooglePlay}
        wrapperClassName={styles.store_link}
        href="https://play.google.com/store/apps/details?id=genesis.nebula&hl=en"
      />
    </div>
    <Copyright className={styles.copyright} />
  </div>
);

export default Success;
