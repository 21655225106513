import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/postQuiz/image_2_hands_with_heart.svg';
import Button from 'src/components/common/Button/Button';
import scrollToBlock from 'src/utils/scrollToBlock';

import styles from './BuyNow.module.scss';

const BuyNow = () => {
  const onBuyNow = () => {
    scrollToBlock('package');
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        Learn how TAURUS zodiac sign is compatible
        with other signs and how to choose who to date or work with
      </p>
      <Img className={styles.img} title="Hands with heart" />
      <Button
        onClick={onBuyNow}
        wrapperClassName={styles.button_wrapper}
        className={styles.button}
        text="Buy now"
      />
    </div>
  );
};

export default BuyNow;
