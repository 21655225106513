import React from 'react';
import address from 'src/assets/images/address-darker.png';

import styles from './Address.module.scss';

const Address = () => (
  <div className={styles.wrapper}>
    <img src={address} alt="address" width={300} height={38} />
  </div>
);

export default Address;
