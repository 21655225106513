import React from 'react';
import { ReactComponent as Img } from 'src/assets/images/quiz/overthink.svg';
// import { ReactComponent as PensiveImg } from 'src/assets/images/emojies/pensive.svg';
// import { ReactComponent as NeutralImg } from 'src/assets/images/emojies/neutral.svg';
// import { ReactComponent as RelievedImg } from 'src/assets/images/emojies/relieved.svg';
import QuizContent from 'src/components/QuizContent/QuizContent';
import RadioChoice from 'src/components/RadioChoice/RadioChoice';
import { ROUTES } from '../../constants/routes';

const choices = [
  'Yes, pretty often',
  'Maybe, sometimes',
  'No, never',
];

const Overthink = () => (
  <QuizContent
    title="Do you tend to overthink things often?"
    img={<Img title="overthink" />}
    radio
    step={2}
    routeBack={ROUTES.GENDER}
  >
    <RadioChoice route={ROUTES.DETAILS} choices={choices} name="overthink" />
  </QuizContent>
);
export default Overthink;
